<template>
  <div class="various">
    <!-- <p>ID：{{ $route.query.id}}</p> -->
    <div class="tab">
      <img class="imgs" @click="goback" :src="`${imgUrl}/img/back.png`"/>
      <div class="tab-title">{{ $route.query.name }}手機安裝說明</div>
    </div>

    <div style="padding:0 20px;margin-top:55px" v-if="$route.query.id == 1" >
      <div class="title2 title">
        1、下載後會彈出風險框，請不用擔心，贝宝体育沒有任何病毒，請點擊「暫不處理」
      </div>
      <img :src="`${imgUrl}/img/Android/HW/1.png`" mode="widthFix" />
      <div class="title2 title">2、安裝後打開「信任次應用」即可</div>
      <img :src="`${imgUrl}/img/Android/HW/2.png`" mode="widthFix" />
      <div class="title2 title">
        3、華為自帶的「手機管家」中會檢測出敏感內容，由於贝宝体育中包含成人內容所致，請放心使用
      </div>
      <img :src="`${imgUrl}/img/Android/HW/3.png`" mode="widthFix" />
    </div>

    <div style="padding:0 20px;margin-top:55px" v-if="$route.query.id == 2">
      <div class="title2 title">
       1、下載後會彈出風險框，請點擊「繼續安裝」
      </div>
      <img :src="`${imgUrl}/img/Android/vivo/1.png`" mode="widthFix" />
      <div class="title2 title">2、在「高風險提示」彈框出現後點擊安裝，如沒有安裝，請重復第一步並點擊「忽略風險並安裝」</div>
      <img :src="`${imgUrl}/img/Android/vivo/2.png`" mode="widthFix" />
    </div>

    <div style="padding:0 20px;margin-top:55px" v-if="$route.query.id == 3">
      <div class="title2 title">
        1、下載後會彈出「發現病毒」，請不用擔心，贝宝体育沒有任何病毒，請點擊「無視風險安裝」
      </div>
      <img :src="`${imgUrl}/img/Android/OPPO/1.png`" mode="widthFix" />
      <div class="title2 title">
        2、oppo自帶的「安全衛士」中會檢測出敏感內容，由於贝宝体育中包含成人內容所致，請放心使用
      </div>
      <img :src="`${imgUrl}/img/Android/OPPO/2.png`" mode="widthFix" />
    </div>

    <div style="padding:0 20px;margin-top:55px" v-if="$route.query.id == 4">
      <div class="title2 title">
        小米手機在正常安裝情況下不會提示病毒，但下載後我們還是需在騰訊手機管家裡把贝宝体育App添加到信任區
      </div>
    </div>

    <div style="padding:0 20px;margin-top:55px" v-if="$route.query.id == 5">
      <div class="title2 title">
        1、下載後會彈出「發現危險」，請不用擔心，贝宝体育沒有任何病毒，請點擊「仍要安裝」
      </div>
      <div
        class="title"
        style="margin-top:40px;margin-bottom:20px;font-size:20px"
      >
        魅族手機管家設置
      </div>
      <img :src="`${imgUrl}img/Android/MZ/1.png`" mode="widthFix" />
      <div class="title2 title">
        1、魅族自帶的「手機管家」點擊「病毒查殺」」
      </div>
      <img :src="`${imgUrl}/img/Android/MZ/5.png`" mode="widthFix" />
      <div class="title2 title">
        2、檢查完成後請點擊「隱患應用」，不用點擊「立即修復」
      </div>
      <img :src="`${imgUrl}/img/Android/MZ/2.png`" mode="widthFix" />
      <div class="title2 title">
        3、進入後在點擊「受信任的應用」
      </div>
      <img :src="`${imgUrl}/img/Android/MZ/3.png`" mode="widthFix" />
      <div class="title2 title">
        4、找到贝宝体育然後勾選點擊「確認」即可
      </div>
      <img :src="`${imgUrl}/img/Android/MZ/4.png`" mode="widthFix" />
    </div>

    <div style="padding:0 20px;margin-top:55px" v-if="$route.query.id == 6">
      <div class="title2 title">
        1、下載前請先至「設置」 「安全性」
      </div>
      <img :src="`${imgUrl}/img/Android/QT/1.png`" mode="widthFix" />
      <div class="title2 title">
        2、開啓「不明的來源」，並執行「確定」
      </div>
      <img :src="`${imgUrl}/img/Android/QT/2.png`" mode="widthFix" />
    </div>

    <div style="padding:0 20px">
      <div class="title" style="margin-top:40px;font-size:20px">
        騰訊手機管家設置
      </div>
      <div class="title2 title">
        1、打開「騰訊手機管家」後點擊「安全檢測」
      </div>
      <img :src="`${imgUrl}/img/Android/TX/1.png`" mode="widthFix" />
      <div class="title2 title">2、在安全檢測後點擊「處理」</div>
      <img :src="`${imgUrl}/img/Android/TX/2.png`" mode="widthFix" />
      <div class="title2 title">3、繼續下一步，點擊「移入信任區」</div>
      <img :src="`${imgUrl}/img/Android/TX/3.png`" mode="widthFix" />
      <div class="title2 title">
        4、點擊「確認」後，白名單添加成功，再次殺毒就不會提示了
      </div>
      <img :src="`${imgUrl}/img/Android/TX/4.png`" mode="widthFix" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  methods: {
    goback() {
      this.$router.go(-1)
    }
  },
  mounted() {}
}
</script>

<style scoped lang="less">
.various {
  .tab {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    height: 50px;
    line-height: 50px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    .imgs {
      padding: 0;
      box-sizing: initial;
      width: 20px;
      height: 20px;
      display: flex;
    }
    .tab-title {
      flex: 1;
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      color: #000;
    }
  }
  .title {
    font-size: 14px;
    color: #333;
    font-weight: 600;
    text-align: center;
  }
  .title2 {
    text-align: left;
    padding: 0 15px;
    margin: 20px 0;
  }
  img {
    padding: 30px;
    width: 100%;
    height: 100%;
  }
  .various {
    padding: 0 15px;
    width: calc(100% - 30px);
    margin: 0 auto;
    margin-top: 20px;
    .item {
      height: 35px;
      margin-bottom: 20px;
      font-size: 16px;
      background-color: #ff5252;
      color: #fff;
      line-height: 35px;
      text-align: center;
    }
  }
}
</style>
